<template>
  <div class="">
    <trac-modal
      v-if="showSubCategoryModal"
      @close="showSubCategoryModal = !showSubCategoryModal"
    >
      <div class="my-12 mx-auto w-3/5">
        <h3 class="text-center text-lg font-bold">
          Product Category and Subcategory
        </h3>
        <p class="text-center text-xs my-4">
          You can select products or subcategory to proceed
        </p>
        <button
          @click="gotoCategoryDetail(selectedCategory)"
          class="my-2 w-full text-left smallest tracking-5 border-primaryBlue border px-4 text-blue-800 py-1 rounded leading-6"
        >
          Go to Products for <span class="uppercase">({{selectedCategory.category.name}})</span>
        </button>
        <trac-dropdown
          class="w-full"
          :choice="true"
          @onselect="selectCategoryFromDropdown"
          title="Select Sub Category"
          :menuItems="computedCategoryList"
        >
        </trac-dropdown>
      </div>
    </trac-modal>
    <!-- Sync Loading UI -->
    <trac-dual-sync
      v-if="isACleanSlate"
      :status="'syncing'"
      :progress="progress"
    ></trac-dual-sync>
    <!-- Normal Loading UI -->
    <main v-else class="md:pl-12 pr-4 md:pr-0">
      <div v-if="categories && categories.length === 0" class="h-128">
        <div
          class="flex flex-col text-center items-center justify-center h-full"
        >
          <span class="font-semibold text-xl text-gray-500"
            >No categories are available.<br />Create a new category by clicking
          </span>
          <div class="mx-auto mt-8">
            <trac-button
              v-if="
                permissionsModules[4].parent.permissionsForUser.createCategory
              "
              class="uppercase text-xs"
              @button-clicked="gotoAddCategory"
              >New Category
            </trac-button>
            <p v-else class="text-sm">No permissions to view this.</p>
          </div>
        </div>
      </div>
      <div class="" v-else>
        <div class="flex flex-row items-end justify-between mb-6">
          <div class="flex-column mt-12">
            <!-- <h2>Products</h2> -->
            <h3 class="font-bold mt-4 text-lg">
              Categories ({{ categories.length }})
            </h3>
          </div>
          <div
            v-if="
              categories &&
              permissionsModules[4].parent.permissionsForUser.createCategory &&
              isOnline
            "
            class="flex-column"
          >
            <trac-button
              class="uppercase text-xs"
              @button-clicked="gotoAddCategory"
              >New Category</trac-button
            >
          </div>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5">
          <div
            v-for="(category, i) in categories"
            :key="i"
            @click="checkForSubCategories(category, true)"
            class="
              single-category
              small-shadow
              bg-white
              rounded-lg
              card-gradient
              hover:text-white
              cursor-pointer
            "
          >
            <div class="flex flex-row items-center">
              <div
                class="
                  rounded-full
                  flex flex-column
                  bg-blue-200
                  mx-5
                  my-8
                  p-4
                  items-center
                "
              >
                <!-- <img class="mx-auto" src="../../assets/svg/Vector.svg" /> -->
                <span class="uppercase">
                  {{ getInitials(category.category.name) }}
                </span>
              </div>
              <ul class="flex-column mr-5 hover:text-white">
                <li class="flex-column font-semibold text-sm capitalize">
                  {{ category.category.name }}
                </li>
                <li class="text-xs">
                  {{ `${category.category.count} Product(s)` }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import AdvancedSearch from "../../offline-module/advancedSearch";
import { cleanSlate } from "../../offline-module/offline.store";
import {
  DELETE_LOCAL_DB_DATA,
  SAVE_LOCAL_DB_DATA,
  GET_USER_BUSINESS_ID,
} from "../../browser-db-config/localStorage";
import { permissionsModules } from "./../settings/permission";

import { eventBus } from "./../../main";
import categories from "../../store/modules/categories";

export default {
  name: "ProductCategories",
  data() {
    return {
      isLoading: false,
      categories: [],
      progress: {
        name: "",
        recordsDone: 0,
        total: 0,
      },
      selectedCategory: {},
      subCategoryList : [],
      showSubCategoryModal: false,
      permissionsModules,
      adv: new AdvancedSearch({
        BusinessId: GET_USER_BUSINESS_ID(),
        offlineManager: this.$GlobalOfflineManager,
      }),
    };
  },
  async created() {
    await this.NetworkCheck();
    this.dualSyncStatus = "syncing";
    await this.loadOfflineRecords();
    // this.isLoading = true;
    // DELETE_LOCAL_DB_DATA("category-detail");
    // await this.fetchAllCategories();
    // this.isLoading = false;
  },
  watch: {
    SWData(newValue, oldValue) {
      console.log("Categories -> SWData, ", newValue); // For debugging/checking this fires in console.log
      if (this.isADownloadOnlySyncEvent(newValue, "categories")) {
        // this.dualSyncStatus = false;
        console.log("category download event detected");
        cleanSlate.moduleList["categories"] = false;
        this.loadOfflineRecords();
      }
      if (this.isAProgressSyncEvent(newValue, "categories")) {
        this.progress = newValue.data;
      }
    },
  },
  computed: {
    computedCategoryList() {
      return this.subCategoryList.map((cat) => {
        return { name: cat.category.name, _id: cat.category._id };
      });
    },
    sortedCategories() {
      return this.categories.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    isACleanSlate() {
      return cleanSlate.moduleList["categories"] === true;
    },
  },
  methods: {
    selectCategoryFromDropdown(data) {
      console.log('emitted from dropdown: ', data)
      const category = this.categories.find(cat => cat.category.name === data.name)
      if(category) {
        this.gotoCategoryDetail(category)
      }
    },
    getInitials(stringValue = "") {
      const split = stringValue.split(" ");
      if (split.length > 1) {
        return `${split[0].charAt(0)}${split[1].charAt(0)}`;
      } else {
        return `${split[0].charAt(0)}${split[0].charAt(1)}`;
      }
      // debugger
    },
    async loadOfflineRecords() {
      // const results = await this.adv.paginateRecords({
      //   tableName: "categories",
      //   pageIndex: 0,
      //   loopThrough: true,
      //   resultsPerSearch: 30,
      // })
      // this.categories = results
      this.categories = await this.$GlobalOfflineManager.getAll("categories");
      this.isLoading = false;
    },
    checkForSubCategories(category, simulateSub = false) {
      this.selectedCategory = category
      const hasSubs = (category.sub || []).length > 0;
      if (hasSubs) {
        this.showSubCategoryModal = true;
        this.subCategoryList = category.sub;
      } else {
        if (simulateSub) {
          this.showSubCategoryModal = true;
          this.subCategoryList = [
            {
              category: {
                business_id: "5c9ba6b2f81f57782def3728",
                _id: "63765004ce214f00030a4778",
                created_at: "2022-11-17T17:15:14.375+01:00",
                updated_at: "2022-11-17T17:15:14.375+01:00",
                name: "bios",
                count: 0,
              },
              sub: [],
            },
            {
              category: {
                business_id: "5c9ba6b2f81f57782def3728",
                _id: "63765003ce214f00030a4776",
                created_at: "2022-11-17T17:15:14.375+01:00",
                updated_at: "2022-11-17T17:15:14.375+01:00",
                name: "extras",
                count: 10,
              },
              sub: [],
            },
            {
              category: {
                business_id: "5c9ba6b2f81f57782def3728",
                _id: "63349a02bf005e0003ab7be8",
                created_at: "2022-09-28T21:01:22.751+01:00",
                updated_at: "2022-09-28T21:01:22.751+01:00",
                name: "fruits",
                count: 0,
              },
              sub: [],
            },
            {
              category: {
                business_id: "5c9ba6b2f81f57782def3728",
                _id: "630480a60b6b59000349fa67",
                created_at: "2022-08-23T09:24:21.369+01:00",
                updated_at: "2022-08-23T09:24:21.369+01:00",
                name: "uncategoried",
                count: 0,
              },
              sub: [],
            },
            {
              category: {
                business_id: "5c9ba6b2f81f57782def3728",
                _id: "62ff7aed71305100031a35e1",
                created_at: "2022-08-19T13:58:36.858+01:00",
                updated_at: "2022-08-19T13:58:36.858+01:00",
                name: "pascal specials",
                count: 0,
              },
              sub: [],
            },
            {
              category: {
                business_id: "5c9ba6b2f81f57782def3728",
                _id: "62dd04c661784d0004feba5a",
                created_at: "2022-07-24T10:37:25.904+01:00",
                updated_at: "2022-07-24T10:37:25.904+01:00",
                name: "medicines",
                count: 0,
              },
              sub: [],
            },
          ];
        } else {
          this.gotoCategoryDetail(category)
        }
      }
    },
    gotoCategoryDetail(category) {
      console.log("proceeding to save category details: ", category);
      SAVE_LOCAL_DB_DATA("category-detail", category);
      this.$router.push({ name: "CategoriesList" });
    },
    gotoAddCategory() {
      DELETE_LOCAL_DB_DATA("category-detail");
      this.$router.push({ name: "AddCategories" });
    },
  },
};
</script>

<style scoped lang="postcss">
.single-category:hover > div > div {
  @apply text-blue-700;
}
.card-gradient:hover {
  background: linear-gradient(180deg, #0143aa 0%, #003283 100%);
}
.little-shadow {
  box-shadow: 0px 0px 10px 0px #1678b014;
}
</style>
