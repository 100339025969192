import { validateArguments, shouldBeArray, shouldBeNumeric, shouldBeObject, shouldBeString, displayErrors, shouldBeBoolean } from './fxnArgumentValidator'
import {hasSyncStatus, hasMetaRecord} from './falseDestination'
import localForage from 'localforage';
import OfflineManager from '.'
export default class AdvancedSearch {
    searchOptions
    allKeys
    offlineKeys
    offlineManager
    tableName
    searchCriteria
    currentIndex
    numberOfPages
    resultsPerSearch
    searchUnsynced
    alreadySearchedOfflineKeys
    ascendingDescending
    progress
    totalizerProgress
    totalizerControllerSignal
    tooFewToLoopThrough
    constructor(options = { BusinessId: '', offlineManager: null, includeUnsynced: false, ascendingDescending: true}) {
        const [valid, errors] = validateArguments(
            [
                // shouldBeString(options.tableName),
                shouldBeString(options.BusinessId),
                options.offlineManager instanceof OfflineManager || 'offlineManager is not an instance of OfflineManager'
            ]
        )
        if (valid) {
            // run function
            this.searchOptions = options
            this.offlineManager = options.offlineManager
            this.allKeys = []
            this.offlineKeys = []
            this.alreadySearchedOfflineKeys = []
            this.numberOfPages = 10
            this.resultsPerSearch = 30
            this.searchUnsynced = options.includeUnsynced
            this.ascendingDescending = options.ascendingDescending
            this.progress = {
                resultsCount: 0,
                totalRecords: 0
            }
            this.totalizerProgress = {
                done: 0,
                total: 0
            }
            this.tooFewToLoopThrough = false
        } else {
            throw new Error(`Advanced Search: ` + JSON.stringify(errors))
        }
    }

    reset() {
        this.progress = {
            resultsCount: 0,
            totalRecords: 0
        }
        this.totalizerProgress = {
            done: 0,
            total: 0
        }
        this.currentIndex = 0
    }

    async loadRecordsUsingKeys(arrayOfKeys, options = { unsyncedOfflineKeys: false }) {
        let tableName = this.tableName
        if (options.unsyncedOfflineKeys) {
            tableName = `${this.tableName}_unsynced`
        }
        const loadedResults = await this.offlineManager.getRecordsUsingKeys(arrayOfKeys, tableName)
        return loadedResults.filter(record => !hasSyncStatus(record, this.tableName) && !hasMetaRecord(record, this.tableName))
    }

    calculateNumberOfPages(totalRecords = 0, safeNumberOfRecords = 1000) {
        let noOfPages = 10;
        const [valid, errors] = validateArguments(
            [
                shouldBeNumeric(totalRecords),
                shouldBeNumeric(safeNumberOfRecords)
            ]
        )

        if (valid) {
            if (totalRecords > safeNumberOfRecords) {
                noOfPages = Math.floor(totalRecords / safeNumberOfRecords);
            }
            console.log(
                `pages needed for loading ${totalRecords} records with a page size of ${safeNumberOfRecords} records each:`,
                noOfPages
            );
        } else {
            throw new Error(`calculateNumberOfPages: ` + displayErrors(errors))
        }
        return noOfPages
    };

    async progressiveLoad(arrayOfKeys, options = { unsyncedOfflineKeys: false }) {
        let results = []
        const [valid, errors] = validateArguments([
            shouldBeArray(arrayOfKeys)
        ])

        if (valid) {
            // Load the records and filter against critera
            results = await this.loadRecordsUsingKeys(arrayOfKeys, options) || []
            // results = this.filterSearch(this.searchCriteria, loaded)
            // debugger
            return results.filter(record => !hasSyncStatus(record, this.tableName) && !hasMetaRecord(record, this.tableName))
        } else {
            throw new Error(`Advanced Search: ` + JSON.stringify(errors))
        }
        // debugger
    }

    filterAcrossRecordFields(dataArray = [], searchTerm = '', recordFields = []) {
        let filteredResults = []
        const [valid, errors] = validateArguments(
            [
                shouldBeArray(dataArray),
                shouldBeString(searchTerm),
                shouldBeArray(recordFields)
            ]
        )

        if (valid) {
            filteredResults = dataArray.filter((record) => {
                let combinedString = ''
                recordFields.forEach((fieldName) => {
                    combinedString += `${record[fieldName]} `
                })
                const searchIndex = (combinedString || '').toLowerCase().search(searchTerm.toLowerCase())
                return searchIndex > -1
            })
            return filteredResults
        } else {
            throw new Error(`searchAccrossRecordFields: ` + displayErrors(errors))
        }
    }

    convertToTimestamp(value = null) {
        let timestamp = ''
        const [valid, errors] = validateArguments(
            [
                (typeof value === 'string' || !isNaN(value)) || 'value is neither string nor number',
            ]
        )

        if (valid) {
            timestamp = new Date(value).getTime()
        } else {
            throw new Error(`searchAccrossRecordFields: ` + displayErrors(errors))
        }
        return timestamp
    }

    deepFind(obj, path = [], index = 0) {
        const [valid, errors] = validateArguments(
            [
                shouldBeObject(obj),
                shouldBeArray(path),
                shouldBeNumeric(index)
            ]
        )

        if (valid) {

            let currentPathHolder = path[index];
            let currentHolder = obj[currentPathHolder];
            let resultHolder = null;
            console.log('deepFind: ', [
                obj,
                path,
                index,
                currentHolder,
                currentPathHolder,
            ]);

            console.log('currentHolder', [currentPathHolder, currentHolder]);
            // currentHolder = deepFind(currentHolder, path, index);

            // if it is an object
            if (typeof currentHolder === 'object' && !Array.isArray(currentHolder)) {
                console.log('currentHolder (when detected as object)', [
                    currentPathHolder,
                    currentHolder,
                ]);
                if (path.length === 1) {
                    resultHolder = currentHolder;
                } else if (index < path.length) {
                    index++;
                    currentPathHolder = path[index];
                    console.log('path after index increment: ', [
                        currentPathHolder,
                        currentHolder,
                        path,
                        index,
                    ]);
                    resultHolder = this.deepFind(currentHolder, path, index);
                } else {
                    resultHolder = currentHolder;
                }
            } else {
                resultHolder = currentHolder;
            }

            return resultHolder;
        } else {
            throw new Error(`deepFind: ` + displayErrors(errors))
        }
    }

    getNested(suppliedObj = null, path) {
        let result = ''
        const [valid, errors] = validateArguments(
            [
                shouldBeObject(suppliedObj),
                shouldBeArray(path)
            ]
        )

        if (valid) {
            result = this.deepFind(suppliedObj, path, 0)
        } else {
            throw new Error(`getNested: ` + displayErrors(errors))
        }
        return result
    }

    filterSearch(where = null, dataArray = [], options = {incrementProgress: false}) {
        // console.log('confirm')
        const compareRecord = (record, where) => {
            const rules = [];
            let isValid = false;
            const ruleKeys = Object.keys(where);
            let nestedPath = null
            let nestedValue = null
            // debugger
            // console.log('ruleKeys', ruleKeys);
            ruleKeys.forEach((rule) => {
                const [operator, value, transformType, nested] = where[rule];
                if (transformType === 'nested') {
                    nestedPath = nested
                    if (typeof record[rule] === 'object' && nestedPath && Array.isArray(nestedPath)) {
                        nestedValue = this.getNested(record[rule], nestedPath)
                        // debugger
                    }
                    // debugger
                }
                // debugger
                switch (operator) {
                    case 'EQ':
                        const isEqualTo = record[rule].toString().toLowerCase() === value.toString().toLowerCase()
                        rules.push(isEqualTo)
                        break;

                    case 'GT':
                        let recordValueGT = record[rule]
                        if (transformType === 'timestamp') {
                            recordValueGT = this.convertToTimestamp(recordValueGT)
                        }
                        const isGreaterThan = parseInt(recordValueGT) > parseInt(value)
                        rules.push(isGreaterThan)
                        break;

                    case 'LT':
                        let recordValueLT = record[rule]
                        if (transformType === 'timestamp') {
                            recordValueLT = this.convertToTimestamp(recordValueLT)
                        }
                        const isLesserThan = parseInt(recordValueLT) < parseInt(value)
                        // debugger
                        rules.push(isLesserThan)
                        break;

                    case 'RANGE':
                        let rangeValue = value
                        let recordValueForRange = record[rule]
                        // debugger
                        if (typeof rangeValue === 'object' && rangeValue && rangeValue.start && rangeValue.end) {
                            //proceed
                            if (transformType === 'timestamp') {
                                const recordValueForRangeTimestamp = this.convertToTimestamp(recordValueForRange)
                                rangeValue.start = this.convertToTimestamp(rangeValue.start)
                                rangeValue.end = this.convertToTimestamp(rangeValue.end)
                                const isInsideRange = parseInt(rangeValue.start) <= parseInt(recordValueForRangeTimestamp) && parseInt(recordValueForRangeTimestamp) <= parseInt(rangeValue.end)
                                rules.push(isInsideRange)
                                // debugger
                            } else {
                                const isInsideRange = parseInt(rangeValue.start) <= parseInt(recordValueForRange) && parseInt(recordValueForRange) <= parseInt(rangeValue.end)
                                rules.push(isInsideRange)
                                // debugger
                            }
                        } else {
                            console.warn('RANGE: rangeValue object not properly formed: ', rangeValue)
                        }
                        break;

                    case 'CONTAINS':
                        let recordToSearch = record[rule] || ''
                        if (transformType === 'nested') {
                            recordToSearch = nestedValue
                        }
                        const isString = typeof value === 'string'
                        const isArray = Array.isArray(value)
                        // debugger
                        let containsString = false

                        if (typeof recordToSearch === 'string') {
                            // if string, search in string
                            if (isString) {
                                containsString = recordToSearch.toLowerCase().indexOf(value.toLowerCase()) > -1 //recordToSearch.search(value) // > 0 ? true : false
                            }
                            // if array, handle accordingly
                            else if (isArray) {
                                // containsString = value.includes(record[rule])
                                containsString = value.findIndex(item => item.toLowerCase() === record[rule].toLowerCase()) > -1
                            }
                            else {
                                containsString = false
                            }
                        }
                        // debugger
                        rules.push(containsString)
                        break;
                }
                // console.log('compareRecord: ', operator, value);
            });
            isValid = rules.every(rule => rule === true)
            console.log('isValid', isValid, 'currentIndex is', this.currentIndex)
            if (isValid && options.incrementProgress) {
                this.progress.resultsCount += 1
            }
            // debugger
            return isValid;
        };

        let results = [];
        if (where && dataArray.length > 0) {
            results = dataArray.filter((record) => {
                const isValid = compareRecord(record, where);
                return isValid
            });
        } else if (dataArray.length < 1) {
            console.warn('empty array supplied for comparison in this.filterSearch()')
        }
        else {
            console.error('incomplete params');
        }
        console.log('results', results);
        return results
    };

    async doSearch(options = { tableName: '', searchCriteria: {}, pageIndex: 0, resultsPerSearch: 0, loopThrough: false }) {
        const [valid, errors] = validateArguments(
            [
                shouldBeString(options.tableName),
                shouldBeObject(options.searchCriteria),
                shouldBeNumeric(options.pageIndex),
                shouldBeNumeric(options.resultsPerSearch),
                shouldBeBoolean(options.loopThrough)
            ]
        )
        if (valid) {
            this.currentIndex = options.pageIndex || 0
            this.tableName = options.tableName
            this.searchCriteria = options.searchCriteria
            let loadedRecords = []
            let results = []
            let selectedBatch = []
            

            // if custom results per search has been set, use that
            if (options.resultsPerSearch) {
                this.resultsPerSearch = options.resultsPerSearch
            }

            this.allKeys = await this.getKeys(options.tableName)

            const splittingProcess = this.splitArray(this.allKeys)
            selectedBatch = this.getSelectedBatch(splittingProcess)
            // debugger
            loadedRecords = await this.progressiveLoad(selectedBatch)

            const searchResults = this.filterSearch(this.searchCriteria, loadedRecords, {incrementProgress: true})
            // debugger

            if (splittingProcess.unSplitable) {
                // if unsplittable, then records must be too few for looping through.
                // return results
                this.tooFewToLoopThrough = true
                results = searchResults
            } else {
                this.tooFewToLoopThrough = false
                // do the usual loop through logic
                if (options.loopThrough) {
                    // Are the results fetched above expected number of results per search?
                    if (searchResults.length > this.resultsPerSearch) {
                        // assign and return
                        results = searchResults
                        // debugger
                    } else {
                        const remaining = this.resultsPerSearch - searchResults.length
                        // debugger
                        // TODO: for some reason, remaining jumped to a higher number and the number
                        // of searches for empty arrays becomes 0, creating an infinite loop of sorts
                        const extraResults = await this.recursiveSearch(options, remaining, 'search')
                        results = [].concat(searchResults, extraResults)
                    }
                } else {
                    results = searchResults
                }
            }


            // const loaded = (await Promise.all(process)).flat() // <-- no longer needed but saved for prosterity
            // debugger
            // this.progress.resultsCount += results.length
            return results

        } else {
            throw new Error(`Advanced Search: ` + JSON.stringify(errors))
        }
    }

    async recursiveSearch(
        options = {
            tableName: '',
            searchCriteria: {},
            pageIndex: 0,
            resultsPerSearch: 0,
            loopThrough: false,
            searchTerm: '',
            recordFields: [],
        },
        remainder = 0,
        searchType = 'search'
    ) {
        const accumulatorArray = []
        let currentSearch = []
        let searchResultsCount = 0
        let results = []
        let validation = []
        switch (searchType) {
            case 'search':
                validation = validateArguments(
                    [
                        shouldBeString(options.tableName),
                        shouldBeObject(options.searchCriteria),
                        shouldBeNumeric(options.pageIndex),
                        shouldBeBoolean(options.loopThrough),
                        shouldBeNumeric(remainder)
                    ]
                )
                break;
            case 'searchAccross':
                validation = validateArguments(
                    [
                        shouldBeString(options.searchTerm),
                        shouldBeArray(options.recordFields),
                        shouldBeString(options.tableName),
                        shouldBeNumeric(options.pageIndex),
                        shouldBeBoolean(options.loopThrough),
                        shouldBeNumeric(remainder)
                    ]
                )
                break;
            case 'paginate':
                validation = validateArguments(
                    [
                        shouldBeString(options.tableName),
                        shouldBeNumeric(options.pageIndex),
                        shouldBeNumeric(options.resultsPerSearch),
                        shouldBeBoolean(options.loopThrough),
                        shouldBeNumeric(remainder)
                    ]
                )
                break;
            default:
                validation = validateArguments(
                    [
                        shouldBeString(options.tableName),
                        shouldBeObject(options.searchCriteria),
                        shouldBeNumeric(options.pageIndex),
                        shouldBeBoolean(options.loopThrough),
                        shouldBeNumeric(remainder)
                    ]
                )
                break;
        }
        const [valid, errors] = validation

        if (valid) {
            const adjustedOptions = JSON.parse(JSON.stringify(options))
            while (searchResultsCount < remainder && this.currentIndex < this.numberOfPages) {
                adjustedOptions.pageIndex++
                adjustedOptions.loopThrough = options.loopThrough || false
                // debugger
                switch (searchType) {
                    case 'search':
                        currentSearch = await this.doSearch(adjustedOptions)
                        break;

                    case 'searchAccross':
                        currentSearch = await this.searchAccrossRecordFields(adjustedOptions)
                        break;

                    case 'paginate':
                        currentSearch = await this.paginateRecords(adjustedOptions)
                        break;

                    default:
                        console.warn('no search type specified for recursiveSearch. returning empty array instead')
                        currentSearch = []
                        break;
                }
                // debugger
                if (currentSearch.length > 0) {
                    accumulatorArray.push(currentSearch)
                }
                searchResultsCount = searchResultsCount + currentSearch.length
            }
            results = accumulatorArray.flat()
            // do a an array of arrays of extra results when remainder is reached
            // and then .flat them!
            // debugger
            return results
        } else {
            throw new Error(`searchAccrossRecordFields: ` + displayErrors(errors))
        }
    }

    async getTotalRecords(tableName = '') {
        let results = 0
        const [valid, errors] = validateArguments(
            [
                shouldBeString(tableName),
            ]
        )

        if (valid) {
            this.allKeys = await this.getKeys(tableName) || []
            results = this.allKeys.length
            this.progress.totalRecords = this.allKeys.length
        } else {
            throw new Error(`getTotalRecords: ` + displayErrors(errors))
        }
        return results
    }

    isSignalAborted (signal = null) {
        if(signal && signal.aborted) {
            console.warn('Signal Was Aborted')
            return true
        } else {
            return false
        }
    }

    async totalizer(options = { tableName: '', recordProperty: '', searchCriteria: {}, abortSignal: null }) {
        const [valid, errors] = validateArguments(
            [
                shouldBeString(options.tableName),
                shouldBeString(options.recordProperty),
                shouldBeObject(options.searchCriteria)
            ]
        )

        if (valid) {
            let totalizerValue = 0
            let internalIndex = 0
            let accumulatorArray = []
            let selectedBatch = []
            let loadedRecords = []
            let searchResults = []

            this.totalizerControllerSignal = options.abortSignal || null

            this.isSignalAborted(this.totalizerControllerSignal)

            if(this.totalizerControllerSignal) {
                this.totalizerControllerSignal.addEventListener('abort', () => {
                    return Promise.reject()
                });
            }

            this.allKeys = await this.getKeys(options.tableName)

            const splittingProcess = this.splitArray(this.allKeys)
            selectedBatch = this.getSelectedBatch(splittingProcess)


            if (splittingProcess.unSplitable) {
                loadedRecords = await this.progressiveLoad(selectedBatch)
                searchResults = this.filterSearch(options.searchCriteria, loadedRecords)
                accumulatorArray.push(searchResults)
            } else {
                for (internalIndex; internalIndex < this.numberOfPages - 1 && this.isSignalAborted(this.totalizerControllerSignal) === false; internalIndex++) {
                    loadedRecords = await this.progressiveLoad(selectedBatch)
                    searchResults = this.filterSearch(options.searchCriteria, loadedRecords)
                    accumulatorArray.push(searchResults)
                    // debugger
                    this.totalizerProgress.done = internalIndex
                    this.totalizerProgress.total = this.numberOfPages
                }
            }
            
            const records = accumulatorArray.flat()
            console.log('totalizerRecord: ', records)
            totalizerValue += searchResults.reduce((accumulator, nextRecord) => {
                // debugger
                return accumulator + nextRecord[options.recordProperty]
            }, 0)

            // if (this.totalizerControllerSignal) {
            // }

            return totalizerValue
        } else {
            throw new Error(`totalizer: ` + displayErrors(errors))
        }
    }

    getSelectedBatch(splittingProcess = {unSplitable : false, objectOfChunks : {},  unSplit: [] }) {
        let selectedBatch = []
        if (splittingProcess.unSplitable) {
            selectedBatch = splittingProcess.unSplit
        } else {
            const chunks = Object.keys(splittingProcess.objectOfChunks)
            // this.currentIndex as the index to select a chunk from objectOfKeys
            const selectedChunk = chunks[this.currentIndex]
            selectedBatch = splittingProcess.objectOfChunks[selectedChunk] || []
        }
        return selectedBatch
    }

    async paginateRecords(options = { tableName: '', pageIndex: 0, resultsPerSearch: 0, loopThrough: false }) {
        let results = []
        let loadResults = []
        let selectedBatch = []
        const [valid, errors] = validateArguments(
            [
                shouldBeString(options.tableName),
                shouldBeNumeric(options.pageIndex),
                shouldBeNumeric(options.resultsPerSearch),
                shouldBeBoolean(options.loopThrough)
            ]
        )

        if (valid) {
            // if custom results per search has been set, use that
            if (options.resultsPerSearch) {
                this.resultsPerSearch = options.resultsPerSearch
            }

            this.currentIndex = options.pageIndex || 0
            this.tableName = options.tableName
            this.allKeys = await this.getKeys(options.tableName)
            
            // const objectOfSplitKeys = this.splitArray(this.allKeys)
            // const chunks = Object.keys(objectOfSplitKeys)
            // this.currentIndex as the index to select a chunk from objectOfKeys
            // const selectedChunk = chunks[this.currentIndex]
            // const selectedBatch = objectOfSplitKeys[selectedChunk] || []

            const splittingProcess = this.splitArray(this.allKeys)
            selectedBatch = this.getSelectedBatch(splittingProcess)
            // debugger
            loadResults = await this.progressiveLoad(selectedBatch)

            if (options.loopThrough) {
                if (loadResults.length > this.resultsPerSearch) {
                    results = loadResults
                } else {
                    const remaining = this.resultsPerSearch - loadResults.length
                    const extraResults = await this.recursiveSearch(options, remaining, 'paginate')
                    results = splittingProcess.unSplitable ? loadResults : [].concat(loadResults, extraResults)
                }
            } else {
                results = loadResults
            }
            // debugger
            return results
        } else {
            throw new Error(`paginateRecords: ` + displayErrors(errors))
        }
    }

    async searchAccrossRecordFields(options = {
        searchTerm: '',
        recordFields: [],
        tableName: '',
        pageIndex: 0,
        loopThrough: false,
        resultsPerSearch: 0
    }) {
        // debugger
        const [valid, errors] = validateArguments(
            [
                shouldBeString(options.searchTerm),
                shouldBeArray(options.recordFields),
                shouldBeString(options.tableName),
                shouldBeBoolean(options.loopThrough)
            ]
        )

        if (valid) {
            this.currentIndex = options.pageIndex || 0
            this.tableName = options.tableName
            this.allKeys = await this.getKeys(options.tableName)
            
            let offlineBatch = []
            let offlineSearchResults = []
            let offlineRecords = []

            if(this.searchUnsynced) {
                this.offlineKeys = await this.getKeys(options.tableName, {unsyncedOfflineKeys: true})
                offlineBatch = this.getOfflineChunk(this.offlineKeys, this.currentIndex)
                // check if keys of offlineBatch have been searched before - 
                // we are using this.alreadySearchedOfflineKeys
                const unsearchedYet = offlineBatch.filter(offlineKey => !this.alreadySearchedOfflineKeys.includes(offlineKey))
                if (unsearchedYet.length > 0) {
                    // load records not searched yet
                    offlineRecords = await this.progressiveLoad(unsearchedYet, {unsyncedOfflineKeys: true})
                    // add keys to this.alreadySearchedOfflineKeys so they don't get duplicated in subsequent loops
                    this.alreadySearchedOfflineKeys = [].concat(this.alreadySearchedOfflineKeys, unsearchedYet)
                } else {
                    offlineRecords = []
                }
                offlineSearchResults = this.filterAcrossRecordFields(offlineRecords, options.searchTerm, options.recordFields)
            }

            // if custom results per search has been set, use that
            if (options.resultsPerSearch) {
                this.resultsPerSearch = options.resultsPerSearch
            }

            const splittingProcess = this.splitArray(this.allKeys)
            const selectedBatch = this.getSelectedBatch(splittingProcess)
            let results = []

            const loadedRecords = await this.progressiveLoad(selectedBatch)
            const searchResults = this.filterAcrossRecordFields(loadedRecords, options.searchTerm, options.recordFields)

            if (splittingProcess.unSplitable) {
                this.tooFewToLoopThrough = true
                results = searchResults
            } else {
                this.tooFewToLoopThrough = false
                if (options.loopThrough) {
                    // Are the results fetched above expected number of results per search?
                    if (searchResults.length > this.resultsPerSearch) {
                        // assign and return
                        results = searchResults
                        // debugger
                    } else {
                        const remaining = this.resultsPerSearch - searchResults.length
                        // debugger
                        const extraResults = await this.recursiveSearch(options, remaining, 'searchAccross')
                        results = [].concat(searchResults, extraResults)
                    }
                } else {
                    results = searchResults
                }
            }


            results = offlineSearchResults.length > 0 ? [].concat(results, offlineSearchResults) : results
            // debugger
            return results
        } else {
            throw new Error(`searchAccrossRecordFields: ` + displayErrors(errors))
        }
    }

    splitArray(suppliedArray = []) {
        const howMany = this.numberOfPages || 10
        const [valid, errors] = validateArguments([
            shouldBeNumeric(howMany),
            shouldBeArray(suppliedArray)
        ])
        let dataArray = []

        if (valid) {
            dataArray = this.ascendingDescending ? suppliedArray : suppliedArray.reverse()
            // console.log('supplied Array for splitting: ', dataArray)
            const firstChunkIndex = Math.floor(dataArray.length / howMany)
            const chunkIndexHolder = {}
            const objectOfChunks = {}
            const splitMeta = {
                unSplitable : true,
                objectOfChunks,
                unSplit: []
            }
            if (dataArray.length < (howMany * howMany)) {
                splitMeta.unSplitable = true
                splitMeta.unSplit = dataArray
            } else {
                splitMeta.unSplitable = false
                /* for howMany, get the starting point index for each 
                batch of full array. Eg: for 100 records / 10 pages,
                get the index of dataArray for each batch:
                    chunkIndexHolder[chunk2] = 2 * 10 = 20 is our starting index for chunkIndexHolder[chunk2]
                    chunkIndexHolder[chunk3] = 3 * 10 = 30 is our starting index for chunkIndexHolder[chunk3]
                */
                for (let i = 1; i < howMany || i === 1; i++) {
                    chunkIndexHolder[`chunk${i}`] = firstChunkIndex * i
                }
                // now get the list of batches as an array ('chunkIndexHolder.chunk1', 'chunkIndexHolder.chunk2' ...)
                const chunks = Object.keys(chunkIndexHolder)
                chunks.forEach((chunk, index) => {
                    const isInBetween = index > 0 && index < chunks.length - 1
                    const isFirst = index === 0
                    const isLast = index === chunks.length - 1
                    
                    // split according to the ranges defined in chunkIndexHolder
                    if (isInBetween) {
                        splitMeta.objectOfChunks[chunk] = dataArray.slice(chunkIndexHolder[chunks[index - 1]], chunkIndexHolder[chunks[index]])
                    } else if (isFirst) {
                        splitMeta.objectOfChunks[chunk] = dataArray.slice(0, chunkIndexHolder[chunk])
                    } else if (isLast) {
                        splitMeta.objectOfChunks[chunk] = dataArray.slice(chunkIndexHolder[chunk])
                    }
                    // debugger
                })
            }
            // make this.allKeys an empty array to free up space
            // this.allKeys = []
            // debugger
            return splitMeta
        } else {
            throw new Error(`Advanced Search: ` + JSON.stringify(errors))
        }
    }

    getOfflineChunk(dataArray = [], index = 0) {
        const [valid, errors] = validateArguments(
            [
                shouldBeArray(dataArray),
                shouldBeNumeric(index)
            ]
        )

        if (valid) {
            const objectOfSplitKeys = this.splitArray(dataArray)
            let results = []
            // if keys are so few they are lesser than the number of pages
            // return un processed
            if (dataArray.length < this.numberOfPages) {
                results = dataArray
            } else {
                // process
                const chunks = Object.keys(objectOfSplitKeys)
                // this.currentIndex as the index to select a chunk from objectOfKeys
                const selectedChunk = chunks[this.currentIndex] || null
                results = selectedChunk ? objectOfSplitKeys[selectedChunk] || [] : []
            }
            return results
        } else {
            throw new Error(`getOfflineChunk: ` + displayErrors(errors))
        }
    }

    resetOfflineKeyTracking() {
        this.alreadySearchedOfflineKeys = []
    }

    async getKeys(tableName = null, options = { unsyncedOfflineKeys: false }) {
        const LFH = localForage;
        LFH.setDriver([localForage.INDEXEDDB]);
        const LI = await LFH.createInstance({
            name: this.searchOptions.BusinessId,
            storeName: options.unsyncedOfflineKeys === false ? tableName : `${tableName}_unsynced`,
        }).keys()
        if (options.unsyncedOfflineKeys === false) {
            this.numberOfPages = this.calculateNumberOfPages(LI.length)
        }
        return LI
    }

}